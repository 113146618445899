import "./App.css";
import Home from "./components/home/index";
import Business from "./components/business";
import Advertising from "./components/advertising";
import Partner from "./components/partner";
import { Route, Routes } from "react-router-dom";
import ScrollUp from "./components/ScrollUp";

function App() {
  return (
    <div className="App">
      <ScrollUp />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/business" element={<Business />}></Route>
        <Route path="/advertise" element={<Advertising/>}></Route>
        <Route path="/partner" element={<Partner/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
